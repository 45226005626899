import { useState } from "react";
import '../reportForm.css'
import Cookies from 'js-cookie'


export default function IndividualReportForm(props){

    const [submitData, setSubmitData] = useState({})
    const handleSubmit = (e)=>  {
        e.preventDefault()
        let cookieValue = Cookies.get('ReportHubAuth')
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/etls/single?DspName=${submitData.dsp}&Month=${submitData.month}&Year=${submitData.year}`, {headers: {
            'Authorization': `Bearer ${cookieValue}`
          }})
        .then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 400){
                    throw new Error(`Status code: ${res.status}. Please double check the input values`)
                }if (res.status === 404){
                    throw new Error(`Status code: ${res.status}. Report file not found on GCS`)
                } else {
                    throw new Error(`Status code: 500. ${res.status}.`)
                }
            }
        })
        .then(data =>{
            console.log(data)
            props.setResponse(data)
        }).catch(err => {
            let data = {}
            data.errorMessage = err.toString()
            data.isError = true
            console.log(data)
            props.setResponse(data)
        })
        props.setVisibility(false)
        props.setResponseVisible(true)
    }

    const handleChange = (e) =>{
        console.log(submitData)
        if(e.target.name === "year"){
            submitData.year = e.target.value;
            console.log("year is changed")
        }
        if(e.target.name === "month"){
            submitData.month = e.target.value;
            console.log("month is changed")
        }
        if(e.target.name === "dsp"){
            submitData.dsp = e.target.value;
            console.log("dsp is changed")
        }
        console.log(submitData)
        setSubmitData(submitData)
    }

    return(
        <div>
            <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                    <label className="form-label">Year</label>
                    <select name="year" className="form-select" value={submitData.year} onChange={handleChange}>
                    <option selected disabled value="">Choose...</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>

                    </select>
                </div>
                <div className="col-md-3">
                    <label for="validationCustom04" className="form-label">Month</label>
                    <select name="month" className="form-select" value={submitData.month} onChange={handleChange}>
                    <option selected disabled value="">Choose...</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <label for="validationCustom04" className="form-label">DSP</label>
                    <select name="dsp" className="form-select" id="validationCustom04" value={submitData.dsp} onChange={handleChange}>
                    <option selected disabled value="">Choose...</option>
                    <option value="AmazonJapan">AmazonJapan</option>
                    <option value="AmazonSummary">AmazonSummary</option>
                    <option value="AmazonUnlimited">AmazonUnlimited</option>
                    <option value="Anghami">Anghami</option>
                    <option value="AppleDjMix">AppleDjMix</option>
                    <option value="AppleMusic">AppleMusic</option>
                    <option value="AppleMusicRadio">AppleMusicRadio</option>
                    <option value="BeatportDownload">BeatportDownload</option>
                    <option value="BeatportStreaming">BeatportStreaming</option>
                    <option value="Deezer">Deezer</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Imusica">iMusica</option>
                    <option value="Itunes">Itunes</option>
                    <option value="ItunesMatch">ItunesMatch</option>
                    <option value="JioSaavnFuga">JioSaavnFuga</option>
                    <option value="Juno">Juno</option>
                    <option value="KKBoxFuga">KKBoxFuga</option>
                    <option value="LineMusicFuga">LineMusicFuga</option>
                    <option value="NapsterFuga">NapsterFuga</option>
                    <option value="NetEaseDownload">NetEaseDownload</option>
                    <option value="NetEaseStreaming">NetEaseStreaming</option>
                    <option value="Pandora">Pandora</option>
                    <option value="QobuzDownload">QobuzDownload</option>
                    <option value="QobuzStreaming">QobuzStreaming</option>
                    <option value="SevenDigital">SevenDigital</option>
                    <option value="SoundCloud">SoundCloud</option>
                    <option value="SoundtrackYourBrand">SoundtrackYourBrand</option>
                    <option value="Spotify">Spotify</option>
                    <option value="TencentFuga">TencentFuga</option>
                    <option value="Tidal">Tidal</option>
                    <option value="TikTok">TikTok</option>
                    <option value="YouTube">YouTube</option>
                    <option value="YouTubeMusic">YouTubeMusic</option>
                    <option value="YouTubePremium">YouTubePremium</option>
                    <option value="YouTubeShorts">YouTubeShorts</option>
                    </select>
                    <div className="invalid-feedback">
                    Please select a valid state.
                    </div>
                </div>
                <div className="col-12">
                    <button className="btn btn-primary" onClick={handleSubmit} type="submit">Process report</button>
                </div>
            </form>
        </div> 
      )
}